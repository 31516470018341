@use "sass:math";
$gray-darker:  lighten(#000, 13.5%);
$gray-dark:    lighten(#000, 20%);
$gray:         lighten(#000, 33.5%);
$gray-light:   lighten(#000, 60%);
$gray-lighter: lighten(#000, 93.5%);

// Theme colors
$brand-primary:         #2F3A59;
$brand-success:         #A68B55;
$brand-info:            #394F59;
$brand-warning:         #D94E4E;
$brand-danger:          #8C1F1F;


//** Global textual link color.
$link-color:            $brand-success;
//** Link hover color set via `darken()` function.
$link-hover-color:      $link-color;
//** Link hover decoration.
$link-hover-decoration: underline;


// $brand-primary: #5F2C5B;
$brand-primary-light:    lighten($brand-primary, 15%);
$brand-primary-lighter:  lighten($brand-primary, 43%);

// $brand-success: #336E37;
$brand-success-light:    lighten($brand-success, 23%);
$brand-success-lighter:  lighten($brand-success, 41%);

// $brand-info:    #AF69A9;
$brand-info-light:       lighten($brand-info, 23%);
$brand-info-lighter:     lighten($brand-info, 36%);

// $brand-warning: #8C8041;
$brand-warning-light:       lighten($brand-warning, 23%);
$brand-warning-lighter:     lighten($brand-warning, 35%);

// $brand-danger:  #863E46;
$brand-danger-light:       lighten($brand-danger, 15%);
$brand-danger-lighter:     lighten($brand-danger, 55%);


// Basic colors
$brand-red:             $brand-danger;
$brand-red-light:       lighten($brand-red, 15%);
$brand-red-lighter:     lighten($brand-red, 55%);

$brand-orange:          #CC7A29;
$brand-orange-light:    lighten($brand-orange, 23%);
$brand-orange-lighter:  lighten($brand-orange, 46%);

$brand-yellow:          #FFCC00;
$brand-yellow-light:    lighten($brand-yellow, 23%);
$brand-yellow-lighter:  lighten($brand-yellow, 44%);

$brand-green:           #336E37;
$brand-green-light:     lighten($brand-green, 23%);
$brand-green-lighter:   lighten($brand-green, 50%);

$brand-blue:            $brand-primary;
$brand-blue-light:      lighten($brand-blue, 23%);
$brand-blue-lighter:    lighten($brand-blue, 50%);

$brand-purple:          #71446D;
$brand-purple-light:    lighten($brand-purple, 23%);
$brand-purple-lighter:  lighten($brand-purple, 55%);

$brand-pink:            #CC3366;
$brand-pink-light:      lighten($brand-pink, 23%);
$brand-pink-lighter:    lighten($brand-pink, 45%);



// Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      #fff;
$navbar-inverse-bg:                         $brand-primary;
$navbar-inverse-border:                     transparent;

// Inverted navbar links
$navbar-inverse-link-color:                 #fff;
$navbar-inverse-link-hover-color:           $brand-success;
$navbar-inverse-link-hover-bg:              transparent;
$navbar-inverse-link-active-color:          #fff;
$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 5%);
$navbar-inverse-link-disabled-color:        #ccc;
$navbar-inverse-link-disabled-bg:           transparent;

// Inverted navbar brand label
$navbar-inverse-brand-color:                $navbar-inverse-link-color;
$navbar-inverse-brand-hover-color:          $navbar-inverse-link-hover-color;
$navbar-inverse-brand-hover-bg:             transparent;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg:            darken($navbar-inverse-bg, 10%);
$navbar-inverse-toggle-icon-bar-bg:         #fff;
$navbar-inverse-toggle-border-color:        darken($navbar-inverse-bg, 10%);



@import "bootstrap-sass/assets/stylesheets/bootstrap";

@import "./basic_colors";
@import "generic_requests/css/address_verifier";


// Body styles
html, body, .container-fluid, .row
{
  height: 100%;
}


.bg-dark
{
  background-color: $gray-darker;
  color: $gray-lighter;
}

.bg-light
{
  background-color: $gray-lighter;
  color: $gray-darker;
}

// Bootstrap extensions

.field_with_errors
{
  @extend .form-group;
  @extend .has-error;
}

.row-skinny
{
  margin-right: 0px;
  margin-left: 0px;
}

.row-bottom-space
{
  margin-bottom: 5px;
}

.text-italics
{
  font-style: italic;
}

.text-bold
{
  font-weight: 600;
}
.strong
{
  font-weight: 600;
}

.inline
{
  display: inline;
}

.table > thead > tr > th.no-line,
.table > tbody > tr > th.no-line,
.table > tfoot > tr > th.no-line,
.table > thead > tr > td.no-line,
.table > tbody > tr > td.no-line,
.table > tfoot > tr > td.no-line
{
  border-top: 0px solid #ffffff;
}

.readonly
{
  margin-top: 7px;
}

.chzn-field
{
  margin-top: 4px;
}

.hidden
{
  display: none;
}

hr.short
{
  margin-top: 10px;
  margin-bottom: 10px;
}

.link-inverse
{
  color: $brand-success;
}

.margins-all-around
{
  margin: 10px;
}

// TODO Temporary
.highlight
{
  // border: 1px solid red;
  background-color: yellow;
}

.link
{
  cursor: pointer;
}

.table-borderless > tbody > tr > td
{
  border: none;
}

.table-short
{
  margin-bottom: 0;
}

.link-inverse
{
  color: lighten($brand-success, 10%);
}
