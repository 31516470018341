@use "sass:math";
.text-primary
{
  color: $brand-primary;
}

.text-info
{
  color: $brand-info;
}

.text-success
{
  color: $brand-success;
}

.text-warning
{
  color: $brand-warning;
}

.text-danger
{
  color: $brand-danger;
}




.text-red
{
  color: $brand-red;
}

.text-orange
{
  color: $brand-orange;
}

.text-yellow
{
  color: $brand-yellow;
}

.text-green
{
  color: $brand-green;
}

.text-blue
{
  color: $brand-blue;
}

.text-purple
{
  color: $brand-purple;
}

.text-pink
{
  color: $brand-pink;
}
